// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { trackUserAction } from '@local/metrics';
import { EvoIcon } from '@local/web-design-system/dist/icons/EvoIcon';
import { getTrace } from '@local/web-design-system/dist/utils/trace';
import Grid from '@mui/material/Grid';
// comment above is to solve error:  React' refers to a UMD global, but the current file is a module.
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { Categories, UserAction } from '../metrics.types';
import { BlockSyncRGBSvg } from '../svg/BlockSyncRGB';
import { CentralDarkMode } from '../svg/CentralDarkMode';
import { CentralRGB } from '../svg/CentralRGB';
import { DriverRGB } from '../svg/DriverRGB';
import EvoBadge from '../svg/EvoBadge';
import { ImagoDarkIcon } from '../svg/ImagoDarkIcon';
import { ImagoRGBIcon } from '../svg/ImagoRGBIcon';
import { MXDepositIcon } from '../svg/MXdepositIcon';
import { OpenGroundRGBLogo } from '../svg/OpenGroundRGBIcon';
import { VariphyRGB } from '../svg/VariphyRGB';
import { useStyles } from './AppsMenu.styles';
import { AppsMenuProps, WaffleMenuItem } from './AppsMenu.types';
import { APPS_MENU_TITLE } from './strings';

// icons commented out until the existence of these icons is desired in prod
const varify = {
    key: 'variphy', // these keys are used in metrics. Do not change
    section: 'Apps',
    image: <VariphyRGB />,
    name: 'Variphy',
    link1: ' https://variphy.seequent.com/',
};

const apps = (variant: string): WaffleMenuItem[] => [
    {
        key: 'evo',
        section: 'Apps',
        image: variant === 'dark' ? <EvoBadge /> : <EvoIcon />,
        name: 'Evo',
        link1: 'https://evo.seequent.com/',
    },
    {
        key: 'driver', // these keys are used in metrics. Do not change
        section: 'Apps',
        image: <DriverRGB />,
        name: 'Driver',
        link1: 'https://driver.seequent.com',
    },
    {
        key: 'blocksync',
        section: 'Apps',
        image: <BlockSyncRGBSvg />,
        name: 'BlockSync',
        link1: 'https://blocksync.seequent.com/',
    },
    {
        key: 'central',
        section: 'Apps',
        image: variant === 'dark' ? <CentralDarkMode /> : <CentralRGB />,
        name: 'Central',
        link1: 'https://central.seequent.com/',
    },
    {
        key: 'imago', // these keys are used in metrics. Do not change
        section: 'Apps',
        image: variant === 'dark' ? <ImagoDarkIcon /> : <ImagoRGBIcon />,
        name: 'Imago',
        link1: 'https://imago.seequent.com/',
    },
    {
        key: 'mx-deposit', // these keys are used in metrics. Do not change
        section: 'Apps',
        image: <MXDepositIcon />,
        name: 'MX Deposit',
        link1: 'https://app.mxdeposit.net/',
    },
    {
        key: 'open-ground', // these keys are used in metrics. Do not change
        section: 'Apps',
        image: <OpenGroundRGBLogo />,
        name: 'OpenGround',
        link1: 'https://portal.openground.bentley.com/',
    },
];

const fullWaffleMenuUrls = ['dev-sqnt', 'evo', 'blocksync', 'blockmodel', 'variphy', 'driver'];

function AppItem(props: { item: WaffleMenuItem; variant: 'light' | 'dark' }) {
    const { item, variant } = props;
    const { classes } = useStyles({ variant });

    return (
        <Grid key={item.key} className={classes.appItem} direction="column" container>
            <a
                href={item.link1}
                target="_blank"
                rel="noreferrer"
                className={classes.itemLink}
                onClick={() =>
                    trackUserAction(UserAction.WAFFLE_MENU_LINK_CLICKED, {
                        label: item.key,
                        category: Categories.WAFFLE_MENU,
                    })
                }
            >
                <Grid item className={classes.itemImage}>
                    {item.image}
                </Grid>
                <Typography className={classes.itemLink}>{item.name}</Typography>
            </a>
        </Grid>
    );
}

export function AppsMenuBase(props: AppsMenuProps) {
    const { variant = 'light' } = props;
    const { classes } = useStyles({ variant });

    function getHeader() {
        const applyTrace = getTrace('apps-menu-tray');
        return (
            <Grid
                item
                container
                alignItems="center"
                direction="row"
                wrap="nowrap"
                className={classnames(classes.header, { [classes.headerDark]: variant === 'dark' })}
            >
                <Grid item className={classes.headerTitleContainer}>
                    <Typography
                        className={classnames(classes.title, {
                            [classes.titleDark]: variant === 'dark',
                        })}
                        {...applyTrace('title')}
                    >
                        {APPS_MENU_TITLE}
                    </Typography>
                </Grid>
            </Grid>
        );
    }

    function getContent() {
        const { evouiVarifyWaffle } = useFlags();
        const waffleApps = apps(variant);
        if (evouiVarifyWaffle) {
            waffleApps.push(varify);
        }

        const appItems = waffleApps.map((item) => {
            // if the current url is in the allowed url list return everything in the appMenu list
            // otherwise the fullWaffleMenuURls acts as a filter
            if (
                fullWaffleMenuUrls.some((allowedUrl) => window.location.href.includes(allowedUrl))
            ) {
                return <AppItem key={item.key} item={item} variant={variant} />;
            }
            if (fullWaffleMenuUrls.some((allowedUrl) => item.link1.includes(allowedUrl))) {
                return null;
            }
            return <AppItem key={item.key} item={item} variant={variant} />;
        });

        return appItems;
    }

    return (
        <Paper className={classes.root}>
            {getHeader()}
            <Grid
                container
                flexDirection="row"
                justifyContent="flex-start"
                className={classes.appMenuContainer}
            >
                {getContent()}
            </Grid>
        </Paper>
    );
}

export const AppsMenu = AppsMenuBase;
