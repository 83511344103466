import { trackUserAction } from '@local/metrics';
import AppsIcon from '@mui/icons-material/Apps';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grid from '@mui/material/Grid';
import Grow from '@mui/material/Grow';
import Popper from '@mui/material/Popper';
import { Instance } from '@popperjs/core'; // mui dependency
import classnames from 'classnames';
import * as React from 'react';

import { AppsMenu } from './AppsMenu/AppsMenu';
import { AppsMenuProps } from './AppsMenu/AppsMenu.types';
import { useStyles } from './AppsMenuButton.styles';
import { Categories, UserAction } from './metrics.types';

function AppsMenuButton({ variant = 'light' }: AppsMenuProps) {
    const { classes } = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const [open, setOpen] = React.useState(false);
    const closeTray = (event?: MouseEvent | TouchEvent) => {
        event?.stopPropagation();
        setOpen(false);
    };
    const openTray = () => {
        trackUserAction(UserAction.WAFFLE_MENU_OPENED, { category: Categories.WAFFLE_MENU });
        setOpen(!open);
    };

    let popperRef: Instance | null = null;
    React.useEffect(() => {
        // Do this after every render
        if (popperRef) {
            // We need to manually trigger an update.
            // Popper has issues knowing when to update its position after its attached dom elements change
            popperRef.update();
        }
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const darkTheme = variant === 'dark';
    const iconClass = classnames({ [classes.icon]: !darkTheme, [classes.iconDark]: darkTheme });

    return (
        <>
            <Grid
                xs
                item
                container
                wrap="nowrap"
                direction="row"
                justifyContent="center"
                alignItems="center"
                onMouseDown={openTray}
                ref={(ref: HTMLDivElement | null) => setAnchorEl(ref)}
                className={classnames(
                    classes.root,
                    { [classes.rootDark]: darkTheme },
                    {
                        [classes.openDark]: open && darkTheme,
                        [classes.open]: open,
                    },
                )}
                automation-id="waffle-menu-button"
            >
                <AppsIcon fontSize="large" className={iconClass} automation-id="apps-icon" />
            </Grid>

            <Popper
                open={open}
                anchorEl={anchorEl}
                placement="bottom-end"
                transition
                disablePortal
                modifiers={[{ name: 'preventOverflow', options: { enabled: true, padding: 0 } }]}
                className={classes.popper}
                popperRef={(ref) => {
                    popperRef = ref;
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={closeTray} mouseEvent="onMouseDown">
                        <Grow {...TransitionProps} style={{ transformOrigin: 'top right' }}>
                            <Grid container className={classes.popperContent}>
                                <AppsMenu variant={variant} />
                            </Grid>
                        </Grow>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
}

export default AppsMenuButton;
